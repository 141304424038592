import React from 'react'
import ProductHeader from '../templates/ProductPage/ProductHeader'
import SEO from '../components/seo'

const PolitiqueDeConfidentialite = () => {
  return (
    <div>
      <SEO title="Politique de confidentialité" description="Page Politique de confidentialité" />
      <ProductHeader title='Politique de confidentialité' />

      <div className='page-legale'>

        <p><i>Dernière mise à jour effectuée le <span
          className='mention'
          data-mention='{{runtime:currentDate}}'>22/09/2021
        </span>.
        </i>
        </p>
        <h3><strong>PREAMBULE</strong></h3>
        <p>La présente politique de confidentialité vous informe sur la manière dont <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                                                     >JH &amp; Co
        </span> utilise
          et protège les informations que vous nous transmettez lorsque vous utilisez le présent site accessible à
          partir de l’URL suivante : <span
            className='mention'
            data-mention='{{company:websiteName}}'
                                     >https://jhnco.fr
          </span> (ci-après le «
          Site »).
        </p>
        <p>Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou complétée à tout
          moment par <span className='mention' data-mention='{{company:companyName}}'>JH &amp; Co</span>, notamment en
          vue de se conformer à toute évolution légale ou technologique. Dans un tel cas, la date de sa mise à jour sera
          clairement identifiée en tête de la présente politique. Ces modifications engagent l’utilisateur dès lors
          qu’ils auront été informés de la mise en ligne de la politique de confidentialité mise à jour, et qu’ils
          auront accepté celle-ci.&nbsp;
        </p>
        <h3><strong>ARTICLE 1. PARTIES</strong></h3>
        <p>La présente politique de confidentialité est applicable entre<strong> </strong>l’éditeur du Site, ci-après
          «&nbsp;<strong>l’Editeur&nbsp;</strong>», et toute personne se connectant au Site, ci-après «&nbsp;
          <strong>l’Utilisateur</strong>&nbsp;».
        </p>
        <h3><strong>ARTICLE 2. DEFINITIONS</strong></h3>
        <p>«&nbsp;<strong>Contenus du Site »</strong> : éléments de toute nature publiés sur le Site, protégés ou non
          par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas,
          structures, bases de données ou logiciels.
        </p>
        <p>«&nbsp;<strong>Editeur</strong>&nbsp;»&nbsp;: <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                         >JH &amp; Co
        </span>, <span
                                                           className='mention' data-mention='{{company:companyType}}'
                                                                  >SARL
                                                                  </span> pris en sa qualité d’éditeur du Site.
        </p>
        <p><strong>«&nbsp;Utilisateur&nbsp;»</strong> : toute personne se connectant au Site.</p>
        <p><strong>«&nbsp;Site&nbsp;»</strong>&nbsp;: site internet accessible à l’URL <span
          className='mention'
          data-mention='{{company:websiteName}}'
                                                                                       >https://jhnco.fr
        </span>,
          ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférant.
        </p>
        <h3><strong>ARTICLE 3. CHAMP D’APPLICATION</strong></h3>
        <p>La présente politique de confidentialité est applicable à tout Utilisateur.&nbsp; Le fait de cliquer sur
          «&nbsp;J’accepte » lors de votre inscription sur le Site emportera votre acceptation pleine et entière de
          celle-ci. De même, le fait de cliquer sur «&nbsp;J’accepte&nbsp;» dans le bandeau d’information relatif aux
          cookies affiché sur le Site emporte votre confirmation de cette acceptation, tout en vous permettant de
          personnaliser les cookies qui vous seront ou non appliqués. Vous reconnaissez du même fait en avoir pris
          pleinement connaissance et les accepter sans restriction.
        </p>
        <p>L’Utilisateur reconnaît la valeur de preuve des systèmes d'enregistrement automatique de l’Editeur et, sauf
          pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.
        </p>
        <p>L'acceptation de la présente politique de confidentialité suppose de la part des Utilisateurs qu'ils
          jouissent de la capacité juridique nécessaire pour cela ou qu’ils aient au moins 16 ans, ou à défaut qu'ils en
          aient l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables, de leur représentant légal s'ils ont
          moins de 16 ans, ou encore qu'ils soient titulaires d'un mandat s'ils agissent pour le compte d'une personne
          morale.
        </p>
        <h3><strong>ARTICLE 4. DONNEES PERSONNELLES</strong></h3>
        <p>Conformément au Règlement général sur la protection des données (RGPD) adopté par le Parlement européen le 14
          avril 2016 et à la législation nationale en vigueur, l’Editeur vous fournit les informations suivantes :
        </p>
        <h4><strong>4.1. Identité du responsable du traitement</strong></h4>
        <p>Le responsable de la collecte et des données traitées sur le Site est <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                                                 >JH &amp; Co
        </span>, <span
                                                                                   className='mention' data-mention='{{company:companyType}}'
                                                                                          >SARL
                                                                                          </span>, dont le siège social se situe <span
                   className='mention' data-mention='{{company:fullAddress}}'
                                                        >1 bis rue blanqui, 94200 Ivry sur seine, FR
                                                        </span>
          <span className='mention' data-mention='{{company:companyRegistrationID}}'>82391901400014</span>.
        </p>
        <h4><strong>4.2. Collecte de données par l’Editeur</strong></h4>
        <h5><strong>4.2.1. Données collectées</strong></h5>
        <h6><i><strong>4.2.1.1 Données collectées lors de la navigation sur le Site</strong></i></h6>
        <p>Lors de la navigation sur le Site, vous consentez à ce que l’Editeur collecte les informations
          relatives&nbsp;: au contenu que vous consultez et sur lequel vous cliquez ; aux données démographiques&nbsp;;
          à l'appareil utilisé et à son environnement logiciel ; à votre localisation&nbsp;; à vos données de connexion
          (horaires, adresse IP…).
        </p>
        <h6><i><strong>4.2.1.2. Données collectées lors de l’utilisation du formulaire de contact ou de l’adresse email
          de contact
        </strong>
        </i>
        </h6>
        <p>L’utilisation du formulaire de contact ou de l’adresse email de contact par l’Utilisateur suppose la collecte
          par l’Editeur des données personnelles suivantes&nbsp;: nom, prénom, adresse e-mail*, numéro de téléphone.
        </p>
        <p>Les données personnelles suivies d’un astérisque sont obligatoires pour l’utilisation du formulaire de
          contact. Les Utilisateurs ne souhaitant pas fournir les informations requises pour l’utilisation du formulaire
          de contact ne pourront pas envoyer un message à l’Editeur directement depuis le Site.
        </p>
        <h6><i><strong>4.2.1.3. Données collectées lors de l’inscription sur le Site</strong></i></h6>
        <p>L’utilisation du formulaire d’inscription par l’Utilisateur suppose la collecte par l’Editeur des données
          personnelles suivantes&nbsp;: nom*, prénom*, adresse postale*, adresse email*, date de naissance, numéro de
          téléphone.&nbsp;
        </p>
        <p>Les données personnelles suivies d’un astérisque sont obligatoires pour l’inscription sur le Site. Les
          Utilisateurs ne souhaitant pas fournir les informations requises pour l’utilisation du formulaire
          d’inscription ne pourront pas s’inscrire directement depuis le Site.
        </p>
        <h6><i><strong>4.2.1.4. Données collectées lors de l’utilisation du formulaire de newsletter</strong></i></h6>
        <p>Dans le cadre de l’utilisation du formulaire de newsletter, l’Editeur peut être amené à collecter et
          traiter&nbsp;: votre adresse mail.
        </p>
        <h5><strong>4.2.2. Finalités de la collecte de données personnelles</strong></h5>
        <p>Les données collectées lors de la navigation font l'objet d'un traitement automatisé ayant pour finalité de
          :
        </p>
        <ul>
          <li>Vérifier l’identité des personnes&nbsp;;</li>
          <li>Assurer et améliorer la sécurité des services&nbsp;;</li>
          <li>Développer, exploiter, améliorer, fournir et gérer le Site ;</li>
          <li>Contextualiser et améliorer l'expérience de l’Utilisateur ;</li>
          <li>Adresser des informations et contacter les personnes, y compris par e-mail&nbsp;;</li>
          <li>Cibler les contenus publicitaires&nbsp;;</li>
          <li>Eviter toute activité illicite ou illégale ;</li>
          <li>Faire respecter les conditions relatives à l'utilisation du Site.</li>
        </ul>
        <p>Les données collectées lors de l’utilisation du formulaire de contact ou de l’adresse email de contact font
          l'objet d'un traitement automatisé ayant pour finalité de :
        </p>
        <ul>
          <li>Vérifier l’identité des personnes&nbsp;;</li>
          <li>Assurer et améliorer la sécurité des services&nbsp;;</li>
          <li>Contextualiser et améliorer l'expérience de l’Utilisateur ;</li>
          <li>Adresser des informations et contacter les personnes, y compris par e-mail&nbsp;;</li>
          <li>Cibler les contenus publicitaires&nbsp;;</li>
          <li>Eviter toute activité illicite ou illégale.</li>
        </ul>
        <p>Les données collectées lors de l’inscription font l'objet d'un traitement automatisé ayant pour finalité de
          :
        </p>
        <ul>
          <li>Exécuter ses engagements contractuels&nbsp;;</li>
          <li>Vérifier l’identité des personnes&nbsp;;</li>
          <li>Assurer et améliorer la sécurité des services&nbsp;;</li>
          <li>Développer, exploiter, améliorer, fournir et gérer le Site ;</li>
          <li>Contextualiser et améliorer l'expérience de l’Utilisateur ;</li>
          <li>Adresser des informations et contacter les personnes, y compris par e-mail&nbsp;;</li>
          <li>Eviter toute activité illicite ou illégale ;</li>
          <li>Faire respecter les conditions relatives à l'utilisation du Site.</li>
        </ul>
        <p>Les données collectées lors de l’utilisation du formulaire de newsletter font l’objet d’un traitement
          automatisé ayant pour finalité de&nbsp;:
        </p>
        <ul>
          <li>adresser des newsletters à l’Utilisateur.</li>
        </ul>
        <h5><strong>4.2.3. Bases juridiques du traitement</strong></h5>
        <p>Les données collectées lors de la navigation ont pour base juridique l’intérêt légitime de l’Editeur, à
          savoir réaliser une analyse des comportements sur le Site et obtenir une sécurité et un fonctionnement
          améliorés du Site. Certaines de ces données, comme celles issues de l’implantation de certains cookies,
          peuvent avoir pour base juridique le consentement des personnes.&nbsp;
        </p>
        <p>Les données collectées lors de l’utilisation du formulaire de contact ou de l’utilisation de l’adresse email
          de contact ont pour base juridique le consentement des personnes concernées.
        </p>
        <p>Les données collectées lors de l’inscription ont pour base juridique une relation contractuelle.</p>
        <p>Les données collectées lors de l’utilisation du formulaire de newsletter ont pour base juridique le
          consentement des personnes concernées.
        </p>
        <h5><strong>4.2.4. Destinataires des données</strong></h5>
        <p>Les données collectées sont consultables uniquement par les membres de la direction de l’Editeur, par le
          personnel en charge de la préparation de votre commande ainsi que par le personnel chargé de la gestion du
          Site, et ne sont jamais rendues librement visualisables par une personne physique tierce.
        </p>
        <h5><strong>4.2.5. Durée de conservation des données personnelles</strong></h5>
        <p>Les données personnelles collectées lors de la navigation sont conservées pendant une durée raisonnable
          nécessaire à la bonne administration du Site et pour un maximum de 12 mois, ou jusqu’au retrait du
          consentement des personnes concernées.&nbsp;
        </p>
        <p>Les données personnelles collectées lors de l’utilisation du formulaire de contact ou lors de l’envoi à
          l’adresse email de contact sont conservées pendant une durée raisonnable nécessaire à la bonne gestion de la
          demande de l’Utilisateur, et pour un maximum de 12 mois.
        </p>
        <p>Les données collectées lors de l’inscription sont conservées pendant la durée de la relation contractuelle
          entre l’Editeur et l’Utilisateur.
        </p>
        <p>Les données collectées lors de l’utilisation du formulaire de newsletter sont conservées jusqu’au retrait du
          consentement des personnes concernées.&nbsp;
        </p>
        <p>A l’issue de chacun de ces délais, l’Editeur archivera ces données et les conservera le temps durant lequel
          sa responsabilité pourra être mise en cause.&nbsp;
        </p>
        <p>Passés ce délai de conservation, l’Editeur s’engage supprimer définitivement les données des personnes
          concernées.
        </p>
        <h5><strong>4.2.6. Sécurité et confidentialité des données personnelles</strong></h5>
        <p>Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens actuels de la
          technique, dans le respect des dispositions du Règlement général pour la protection des données et de la
          législation nationale en vigueur.
        </p>
        <h5><strong>4.2.7. Minimisation des données personnelles</strong></h5>
        <p>L’Editeur peut également collecter et traiter toute donnée transmise volontairement par un Utilisateur,
          notamment via le champ libre du formulaire de contact.&nbsp;
        </p>
        <p>L’Editeur oriente les Utilisateurs autant que possible lorsqu’ils fournissent des données à caractère
          personnel inutiles ou superflues.
        </p>
        <p>L’Editeur s’engage à ne conserver et traiter que les données strictement nécessaires à ses activités, et
          supprimera toute donnée reçue non utile dans les plus brefs délais.
        </p>
        <h4><strong>4.3. Respects des droits</strong></h4>
        <p>Vous disposez des droits suivants concernant vos données personnelles, que vous pouvez exercer en nous
          écrivant à notre adresse postale ou en adressant un email à l’adresse suivante&nbsp;: <span
            className='mention' data-mention='{{privacy:concernEmail}}'
                                                                                                >jhandcoparis@gmail.com
          </span>.
        </p>
        <h5><strong>4.3.1. Droit d’information, d’accès et de communication des données</strong></h5>
        <p>Vous avez la possibilité d’accéder aux données personnelles qui vous concernent.</p>
        <p>En raison de l’obligation de sécurité et de confidentialité dans le traitement des données à caractère
          personnel qui incombe à l’Editeur, votre demande sera uniquement traitée si vous rapportez la preuve de votre
          identité, notamment par la production d’un scan de votre titre d’identité valide (en cas de demande par email)
          ou d’une photocopie signée de votre titre d’identité valide (en cas de demande adressée par écrit), tous deux
          accompagnés de la mention «&nbsp;j’atteste sur l’honneur que la copie de cette pièce d’identité est conforme à
          l’original. Fait à … le …&nbsp;», suivie de votre signature.
        </p>
        <p>Pour vous aider dans votre démarche, vous trouverez <a
          href='https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces' target='_blank'
                                                               >ici
        </a>&nbsp;un modèle
          de courrier élaboré par la Cnil.
        </p>
        <h5><strong>4.3.2. Droit de rectification, de suppression et droit à l’oubli des données</strong></h5>
        <p>Vous avez la possibilité de demander la rectification, la mise à jour, le verrouillage ou encore l’effacement
          de vos données personnelles qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou
          obsolètes.
        </p>
        <p>Vous pouvez également définir des directives générales et particulières relatives au sort des données à
          caractère personnel après votre décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de
          prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.
        </p>
        <p>Pour vous aider dans votre démarche, vous trouverez <a
          href='https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees'
          target='_blank'
                                                               >ici
        </a>&nbsp;un modèle de courrier élaboré par la Cnil.
        </p>
        <h5><strong>4.3.3. Droit d’opposition au traitement de données</strong></h5>
        <p>Vous avez la possibilité de vous opposer à un traitement de vos données personnelles.&nbsp;</p>
        <p>Pour se faire, il vous conviendra d’adresser un email à l’adresse suivante&nbsp;: <span
          className='mention'
          data-mention='{{privacy:concernEmail}}'
                                                                                             >jhandcoparis@gmail.com
        </span>.
          Dans ce mail, il vous faudra préciser les données que vous souhaitez voir supprimer ainsi que les raisons
          justifiant cette demande, sauf cas de prospection commerciale.
        </p>
        <h5><strong>4.3.4. Droit à la portabilité des données</strong></h5>
        <p>Vous avez le droit de recevoir les données personnelles que vous nous avez fournies dans un format
          transférable, ouvert et lisible.
        </p>
        <h5><strong>4.3.5. Droit à la limitation du traitement</strong></h5>
        <p>Vous avez le droit de demander que le traitement de vos données personnelles par l’Editeur soit limité.
          Ainsi, vos données ne pourront qu’être conservées et non plus utilisées par l’Editeur.
        </p>
        <h5><strong>4.3.6. Retrait du consentement</strong></h5>
        <p>Votre consentement est indispensable au traitement de vos données par l’Editeur. Toutefois, il vous est
          possible de retirer celui-ci à tout moment. Ce retrait conduira à la suppression des données personnelles vous
          concernant.&nbsp;
        </p>
        <p>Les services nécessitant le traitement de vos données par l’Editeur ne seront néanmoins plus accessibles.</p>
        <h5><strong>4.3.7. Délais de réponse</strong></h5>
        <p>L’Editeur s’engage à répondre à votre demande d’accès, de rectification ou d’opposition ou toute autre
          demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la
          réception de votre demande.
        </p>
        <h5><strong>4.3.8. Plainte auprès de l’autorité compétente</strong></h5>
        <p>Si vous considérez que l’Editeur ne respecte pas ses obligations au regard de vos informations personnelles,
          vous pouvez adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité
          compétente est la Cnil à laquelle vous pouvez adresser une demande <a
            href='https://www.cnil.fr/fr/plaintes/internet' target='_blank'
                                                                             >ici
          </a>.
        </p>
        <h4><strong>4.4. Transfert des données collectées&nbsp;</strong></h4>
        <h5><strong>4.4.1. Transfert à des partenaires</strong></h5>
        <p>L’Editeur vous informe que nous avons recours à des prestataires habilités pour faciliter le recueil et le
          traitement des données que vous nous avez communiquées. Ces prestataires peuvent être situés en dehors de
          l’Union Européenne et ont communication des données recueillies sur le Site.
        </p>
        <p>L’Editeur s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du
          respect de conditions strictes en matière de confidentialité, d’usage et de protection des données, par
          exemple via le PrivacyShield états-unien.
        </p>
        <p>L’Utilisateur consent à ce que les données collectées soient transmises par l’Editeur à ses partenaires et
          fassent l’objet d’un traitement par ces partenaires dans le cadre des services tiers, à savoir&nbsp;:
        </p>
        <p>Pas de partenaire connu pour le moment.</p>
        <h5><strong>4.4.2. Transfert sur réquisition ou décision judiciaire</strong></h5>
        <p>L’Utilisateur consent également à ce que l’Editeur communique les données collectées à toute personne, sur
          réquisition d’une autorité étatique ou sur décision judiciaire.
        </p>
        <h5><strong>4.4.3. Transfert dans le cadre d'une fusion ou d'une acquisition</strong></h5>
        <p>Si l’Editeur est impliqué dans une fusion, une vente d'actifs, une opération de financement, une liquidation
          ou banqueroute ou dans une acquisition de tout ou partie de son activité par une autre société, l’Utilisateur
          consent à ce que les données collectées soient transmises par l’Editeur à cette société et que cette société
          opère les traitements de données personnelles visés dans la présente politique de confidentialité au lieu et
          place de l’Editeur.
        </p>
        <h3><strong>ARTICLE 5. POLITIQUE RELATIVE AUX TRACEURS/COOKIES</strong></h3>
        <p>Lors de votre première connexion sur le Site de l’Editeur, vous êtes avertis par un bandeau en bas de votre
          écran que des informations relatives à votre navigation sont susceptibles d’être enregistrées dans des
          fichiers dénommés « cookies ». Notre politique d’utilisation des cookies vous permet de mieux comprendre les
          dispositions que nous mettons en œuvre en matière de navigation sur notre Site. Elle vous informe notamment
          sur l’ensemble des cookies présents sur notre Site, leur finalité, et vous donne la marche à suivre pour les
          paramétrer.
        </p>
        <h4><strong>5.1. Utilisation de traceurs/cookies</strong></h4>
        <p>L’Editeur du présent Site pourra procéder à l’implantation d’un cookie et autre traceur sur le disque dur de
          votre terminal (ordinateur, tablette, mobile, etc.) afin de vous garantir une navigation fluide et optimale
          sur notre site Internet.
        </p>
        <p>Les « cookies » (ou témoins de connexion) sont des petits fichiers texte de taille limitée qui nous
          permettent de reconnaître votre ordinateur, votre tablette ou votre mobile aux fins de personnaliser les
          services que nous vous proposons.
        </p>
        <p>Pour mieux vous éclairer sur les informations que les cookies identifient, vous trouverez ci-dessous un
          tableau listant les différents types de cookies susceptibles d’être utilisés sur le site de l’Editeur, leur
          nom, leur finalité ainsi que leur durée de conservation.
        </p>
        <h4><strong>5.2. Finalités des traceurs</strong></h4>
        <p>Avec l'aide des informations contenues dans les traceurs et les cookies utilisés l’Editeur peut analyser la
          fréquentation et l’utilisation faite du Site et, le cas échéant, faciliter et améliorer la navigation,
          réaliser des opérations de prospection, élaborer des statistiques commerciales ou afficher des publicités
          ciblées.
        </p>
        <h4><strong>5.3. Traceurs utilisés</strong></h4>
        <p>&nbsp;</p>
        <table>
          <tbody>
            <tr>
              <td>Shopify</td>
            </tr>
          </tbody>
        </table>
        &nbsp;
        <p>&nbsp;</p>
        <h4><strong>5.4. Configuration de vos préférences sur les cookies</strong></h4>
        <p>Lors de votre première connexion sur le Site de l’Editeur, une bannière présentant brièvement des
          informations relatives au dépôt de cookies et de technologies similaires apparaît en bas de votre écran. Cette
          bannière vous permet d’opérer un choix quant aux cookies dont vous acceptez ou refusez le dépôt sur votre
          terminal. Vous serez réputé avoir donné votre accord au dépôt de cookies en cliquant sur l’icône
          «&nbsp;J’accepte&nbsp;», soit de façon globale, soit de façon individualisée. A l’inverse, vous serez réputé
          avoir refusé le dépôt de cookies en cliquant sur l’icône «&nbsp;Je refuse&nbsp;», une nouvelle fois, ce choix
          pourra porter sur l’ensemble des cookies, ou sur certains d’entre eux seulement. A défaut de choix, vous serez
          réputé avoir refusé le dépôt de cookies. Votre décision sera enregistrée pour 6 mois et pourra faire l’objet
          d’une modification à tout moment.
        </p>
        <h5><strong>5.4.1 Les cookies exemptés de consentement</strong></h5>
        <p>Conformément aux recommandations de la Commission Nationale de l’Informatique et des Libertés (Cnil),
          certains cookies sont dispensés du recueil préalable de votre consentement dans la mesure où ils sont
          strictement nécessaires au fonctionnement du site internet ou ont pour finalité exclusive de permettre ou
          faciliter la communication par voie électronique. Il s’agit notamment des cookies d’identifiant de session,
          d’authentification, de session d’équilibrage de charge ainsi que des cookies de personnalisation de votre
          interface. Ces cookies sont intégralement soumis à la présente politique dans la mesure où ils sont émis et
          gérés par l’Editeur.
        </p>
        <h5><strong>5.4.2 Les cookies nécessitant le recueil préalable de votre consentement</strong></h5>
        <p>Cette exigence concerne les cookies émis par des tiers et qui sont qualifiés de « persistants » dans la
          mesure où ils demeurent dans votre terminal jusqu’à leur effacement ou leur date d’expiration.
        </p>
        <p>De tels cookies étant émis par des tiers, leur utilisation et leur dépôt sont soumis à leurs propres
          politiques de confidentialité. Cette famille de cookie regroupe les cookies de mesure d’audience, les cookies
          publicitaires ainsi que les cookies de partage de réseaux sociaux (comme par exemple Facebook , Twitter,
          Youtube et Instagram).
        </p>
        <p>Les cookies de mesure d’audience établissent des statistiques concernant la fréquentation et l’utilisation de
          divers éléments du Site (comme les contenus/pages que vous avez visitées). Ces données participent à
          l’amélioration de l’ergonomie du Site de l’Editeur.
        </p>
        <h4><strong>5.5. Durée de conservation maximale des traceurs</strong></h4>
        <p>Les traceurs ont vocation à être conservés sur le poste informatique de l’Utilisateur pour une durée allant
          jusqu'à 12 mois. Ces données sont conservées dans des conditions sécurisées, selon les moyens actuels de la
          technique, dans le respect des dispositions du Règlement général à la protection des données et de la
          législation nationale en vigueur.
        </p>
        <h4><strong>5.6. Opposition à l’utilisation de traceurs</strong></h4>
        <h5><strong>5.6.1. Faculté d’opposition à l’utilisation de traceurs</strong></h5>
        <p>Vous pouvez accepter ou refuser le dépôt de cookies à tout moment.</p>
        <p>L’Utilisateur peut supprimer ou désactiver l’utilisation des traceurs chaque fois qu’il le souhaite en
          modifiant les paramètres de son navigateur. Il est possible de consulter le Site sans traceurs. Certaines
          fonctions annexes du Site peuvent toutefois ne pas fonctionner si l’Utilisateur a désactivé l’utilisation des
          traceurs, telles que l’autocomplétion des formulaires ou les indicateurs de navigation.
        </p>
        <h5><strong>5.6.2. Paramétrages</strong></h5>
        <p>Pour de plus amples informations concernant les outils de maîtrise des cookies, vous pouvez consulter <a
          href='https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser' target='_blank'
                                                                                                                 >ici
        </a> la page dédiée sur
          le site internet de la Cnil.
        </p>
        <h6><i><strong>5.6.2.1. Paramétrages du navigateur</strong></i></h6>
        <p>Chaque navigateur Internet propose ses propres paramètres de gestion des cookies. L’Utilisateur peut
          configurer son logiciel de navigation de manière à ce que les cookies soient rejetés, soit systématiquement,
          soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce
          que son acceptation ou son refus des cookies lui soit proposé ponctuellement, avant qu’un cookie ne soit
          susceptible d’être enregistré sur son terminal.
        </p>
        <p>Pour la gestion des cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est
          différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière
          modifier ses souhaits en matière de cookies :
        </p>
        <ul>
          <li><a href='http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies' target='_blank'>Pour
            Internet Explorer
          </a>&nbsp;;
          </li>
          <li><a href='http://support.apple.com/fr-ch/HT1677' target='_blank'>Pour Safari</a>&nbsp;;</li>
          <li><a
            href='http://support.google.com/chrome/bin/answer.py?hl=fr&amp;hlrm=en&amp;answer=95647'
            target='_blank'
              >Pour Chrome
          </a>&nbsp;;
          </li>
          <li><a
            href='http://support.mozilla.org/fr/kb/Activer%252520et%252520d%2525C3%2525A9sactiver%252520les%252520cookies'
            target='_blank'
              >Pour Firefox
          </a>&nbsp;;
          </li>
          <li><a href='http://help.opera.com/Windows/10.20/fr/cookies.html' target='_blank'>Pour Opera</a>.</li>
        </ul>
        <h6><i><strong>5.6.2.2. Paramétrages au moyen de modules complémentaires</strong></i></h6>
        <p>L’Utilisateur peut également supprimer ou s’opposer à l’implantation de cookies sur son poste en installant
          une extension sur son navigateur, telle que Ghostery, à télécharger <a
            href='http://www.ghostery.com'
            target='_blank'
                                                                              >ici
          </a>.&nbsp;
        </p>
        <h3><strong>ARTICLE 6. PROPRIETE INTELLECTUELLE&nbsp;</strong></h3>
        <h4><strong>6.1. Protection légale des Contenus du Site</strong></h4>
        <p>Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de
          données. Toute représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle,
          réalisée illégalement et sans le consentement de l’Editeur ou de ses ayants droit ou ayants cause constitue
          une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à
          des poursuites judiciaires pour contrefaçon
        </p>
        <h4><strong>6.2. Protection contractuelle des Contenus du Site</strong></h4>
        <p>L’Utilisateur s'engage contractuellement à l'égard de l’Editeur à ne pas utiliser, reproduire ou représenter,
          de quelque manière que ce soit, les Contenus du Site, qu'ils soient ou non protégés par un droit de propriété
          intellectuelle, à une autre fin que celle de leur lecture par un robot ou un navigateur. Cette interdiction
          n'est pas applicable aux robots d'indexation ayant pour seul objet de scanner le contenu du Site aux fins
          d'indexation.
        </p>
        <h3><strong>ARTICLE 7. STIPULATIONS FINALES</strong></h3>
        <h4><strong>7.1. Modifications&nbsp;</strong></h4>
        <p>La présente Politique de Confidentialité peut être modifiée à tout moment par l’Editeur. Les conditions
          applicables à l’Utilisateur sont celles en vigueur lors de sa connexion sur le Site. Toute modification
          substantielle de la présente politique de confidentialité fera l’objet d’une information lors de la première
          connexion de l’Utilisateur suite à leur entrée en vigueur. Cette nouvelle politique de confidentialité devra
          alors faire l’objet d’une nouvelle acceptation.
        </p>
        <h4><strong>7.2. Entièreté</strong></h4>
        <p>La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou
          du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties
          devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant
          à l'esprit et à l'objet des présentes.
        </p>
        <h4><strong>7.3. Non-renonciation</strong></h4>
        <p>L'absence d'exercice par l’Editeur des droits qui lui sont reconnus par les présentes ne pourra en aucun cas
          être interprétée comme une renonciation à faire valoir lesdits droits.
        </p>
        <h4><strong>7.4. Langues</strong></h4>
        <p>Les présentes conditions sont proposées en français.</p>
        <h4><strong>7.5. Clauses abusives</strong></h4>
        <p>Les stipulations des présentes conditions s'appliquent sous réserve du respect des dispositions impératives
          du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un professionnel et
          un consommateur.
        </p>
        <h3><strong>ARTICLE 8. LITIGES</strong></h3>
        <h4><strong>8.1. Droit applicable</strong></h4>
        <p>La présente Politique de Confidentialité est soumise à l'application du droit français et de la
          règlementation européenne, notamment le Règlement européen sur la protection des données.
        </p>
        <h4><strong>8.2. Litiges</strong></h4>
        <p>En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de
          l'exécution des présentes conditions générales et dont la solution n’aura pu être trouvée préalablement à
          l’amiable entre les parties devra être soumis.
        </p>
        <p>Depuis le 1er janvier 2016, la médiation est obligatoire pour tous. Ainsi, tout professionnel vendant à des
          particuliers, est tenu de communiquer les coordonnées d'un Médiateur compétent en cas de litige, et ce peu
          importe qu'il vende à distance ou dans un magasin physique (Source: FEVAD).
        </p>
        <p><span className='mention' data-mention='{{company:mediationServiceName}}'>Contact</span> &nbsp;/&nbsp; <span
          className='mention' data-mention='{{company:mediationUrl}}'
                                                                                                                  >https://jhnco.fr/contact
        </span>
        </p>
      </div>
    </div>
  )
}

export default PolitiqueDeConfidentialite
